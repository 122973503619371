define('gmc/routes/dashboard', ['exports', 'gmc/routes/base', 'gmc/mixins/game-code', 'gmc/config/environment'], function (exports, _base, _gameCode, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend(_gameCode.default, {

    // Defaults

    gmc: Ember.inject.service(),

    session: Ember.inject.service(),

    // Single line CP

    gameCode: Ember.computed.alias('gmc.session.game.code'),

    // Multiline CP

    // Lifecycle hooks
    beforeModel: function beforeModel(transition) {
      if (!this.session.isAuthenticated) {
        // get(this, 'gmc').clearSession();
        this.transitionTo('login');
        return;
      }

      if (transition.queryParams.close) {
        try {
          window.close();
        } catch (error) {
          console.log(error);
        }
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.transitionTo('dashboard', Ember.get(this, 'gmc.session.game.code'));
    }

    // Private

  });
});