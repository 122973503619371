define('gmc/routes/login/index', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    // Defaults

    gceSession: Ember.inject.service(),
    gmc: Ember.inject.service(),
    session: Ember.inject.service(),

    // Single line CP

    // Multiline CP

    // Lifecycle hooks

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);
      // Clean up previous cached game settings
      Ember.get(this, 'gmc').clearSession();

      if (transition.queryParams.gameCode) {
        if (transition.queryParams.destination) {
          this.gmc.destination = transition.queryParams.destination;
          //eg. gameCode=gr&destination=live-ops/m/e7e4a3b9-fc63-4b96-ab92-167a9f6ed9ff
        }
        if (transition.queryParams.close) {
          this.gmc.close = transition.queryParams.close;
        }
        var currentGame = void 0;
        this.gmc.global.games.forEach(function (game) {
          if (game.code === transition.queryParams.gameCode) {
            currentGame = game;
            return;
          }
        });

        if (!currentGame) {
          Ember.get(this, 'gmc').clearSession();
          this.transitionTo('login.select-game');
          return;
        }

        this.gmc.setSession(currentGame);
        Ember.get(this, 'session').authenticate('authenticator:torii', 'azure-oauth2').then(function () {
          // On sign-in, fetch game settings
          Ember.get(_this, 'gmc.fetchSettings').perform();
        }).catch(function (reason) {
          if (reason) {
            Ember.set(_this, 'errorMsg', reason);
          }
          Ember.set(_this, 'signingIn', false);
        });

        return;
      } else {
        // If a game hasn't been selected, redirect
        if (!Ember.get(this, 'gmc.session.game')) {
          this.transitionTo('login.select-game');
          // If already signed in, redirect
        }
      }
    }

    // Private

  });
});